export default [
  {
    path: '/dashboard/mensuel',
    name: 'dashboard-mensuel',
    component: () => import('@/views/dashboard/hebdo/DashboardMensuel.vue'),
  },
  {
    path: '/dashboard/trimestriel',
    name: 'dashboard-trimestriel',
    component: () => import('@/views/dashboard/hebdo/DashboardTrimestriel.vue'),
  },
]
