/* eslint-disable eqeqeq */
import Vue from 'vue'

// axios
import axios from 'axios'

const baseURL = 'http://localhost:8081/api/v1/'
const baseURLProd = 'https://api.acekare.com/api/v1/'

const axiosIns = axios.create({
  // eslint-disable-next-line no-restricted-globals
  // eslint-disable-next-line eqeqeq
  // eslint-disable-next-line no-restricted-globals
  baseURL: location.host == 'erp2.acekare.com' ? baseURLProd : baseURL,
})

Vue.prototype.$http = axiosIns

export default axiosIns
