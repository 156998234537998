export default [
  {
    path: '/catalogue/inventaire',
    name: 'products',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/catalogue/bom-list',
    name: 'bom-list',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  {
    path: '/catalogue/fournisseurs',
    name: 'suppliers',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
]
